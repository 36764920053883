import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MentionsFr = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Mentions légales"
      description="Responsable de la publication : Ludovic GUYOT"
    />
    <h3 className="mt-6 pt-5">Responsable de la publication</h3>
    <p>Lacuzon Loisirs</p>
    <p>Ludovic Guyot</p>
    <p>
      315 chemin de Montanoisel
      <br /> 39210 MONTAIN
    </p>
    <h3 className="mt-6">Hébergeur</h3>
    <p>OVH</p>
    <p>
      2 rue Kellermann
      <br />
      59100 Roubaix
      <br />
      FRANCE
      <br />
      <a href="https://www.ovh.com">www.ovh.com</a>
    </p>
  </Layout>
)

export default MentionsFr
